import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-primary.svg'

import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  ButtonGroup,
} from 'react-bootstrap'
const path = typeof window !== 'undefined' ? window.location.pathname : ''
const TransparentHeader = (props) => (
  <div className="position-absolute top right w-100 z-toppest">
    <Navbar
      expand="xl"
      className={` p-0 ${props.className} justify-content-end`}
    >
      {!props.noLogo && (
        <Navbar.Brand href="/">
          <img src={logo} />
        </Navbar.Brand>
      )}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end align-items-end"
      >
        <Nav>
          <div className="text-right d-lg-flex">
            <Nav.Link
              href="/about"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              About
            </Nav.Link>
            <Nav.Link
              href="/issues"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              Issues
            </Nav.Link>
            <Nav.Link
              href="/public-safety"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              Public Safety
            </Nav.Link>
            <Nav.Link
              href="/greennewdeal"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              Green New Deal
            </Nav.Link>

            <div style={{ marginRight: '8px' }}>
              <Nav.Link
                href="https://contribute.nycvotes.org/campaigns/tiffanycaban/contributions/new"
                className="d-inline-block font-sans-serif font-weight-bold rounded px-3 py-2 color-white bg-base2"
              >
                Donate
              </Nav.Link>
            </div>

            <ButtonGroup aria-label="Basic example">
              <Button size="sm" variant="light" active>
                <a href={path}>English</a>
              </Button>
              <Button size="sm" variant="light">
                <a href={`/es${path} `}>Español</a>
              </Button>
            </ButtonGroup>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
)

export default TransparentHeader
